import * as XLSX from 'xlsx';
import { roundNoFixed } from 'components/utils';
import { unit } from 'mathjs';
import { doc, getDoc } from 'firebase/firestore';

export const generateInventorySheet = async (ws, lastOps, tankDataMap, volumeUnit, extraOperationFields, t, cellarsRef) => {
  const headerTranslations = {
    cellarName: t('daily_inventory.headers.cellarName'),
    tankName: t('daily_inventory.headers.tankName'),
    height: t('daily_inventory.headers.finalHeight'),
    volume: t('daily_inventory.headers.finalVolume', { unit: volumeUnit }),
    abvAt20c: t('daily_inventory.headers.abvAt20c'),
    pureAlcohol: t('daily_inventory.headers.pureAlcohol', { unit: volumeUnit }),
    utilCapacity: t('daily_inventory.headers.util_capacity', { unit: volumeUnit }),
  };

  const headers = Object.values(headerTranslations);

  extraOperationFields.forEach((field) => {
    headers.push(field.display);
  });

  headers.push(t('daily_inventory.headers.last_op_date'));

  // Add headers to the worksheet
  XLSX.utils.sheet_add_aoa(ws, [headers], { origin: 'A1' });

  // Define column widths
  const columnWidths = [
    { wch: 20 },
    { wch: 20 },
    { wch: 20 },
    { wch: 20 },
    { wch: 20 },
    { wch: 20 },
    ...extraOperationFields.map(() => ({ wch: 30 })),
    { wch: 30 },
    { wch: 30 },
  ];

  ws['!cols'] = columnWidths;

  const dataPromises = Object.entries(tankDataMap).map(async ([tankId, tankData]) => {
    const operation = lastOps.find(op => op.tankNumber === tankId);
    const { compartment } = tankData.fullActiveCert || {};
    const maxVolume = compartment ? roundNoFixed(unit(compartment.maxVolume, 'L').toNumber(volumeUnit), 4) : '';

    if (!operation) {
      // Fetch cellar data only when there's no operation
      let cellarName = '';
      const cellarRef = doc(cellarsRef, tankData.cellar);
      const cellarDoc = await getDoc(cellarRef);
      cellarName = cellarDoc.exists() ? cellarDoc.data().name : '';

      const emptyRow = [
        cellarName, // cellarName from cellar document
        tankData.name || tankId, // tankName
        '', // height
        '', // volume
        '', // abvAt20c
        '', // pureAlcohol
        maxVolume, // utilCapacity
      ];

      // Add empty values for extra fields
      extraOperationFields.forEach(() => {
        emptyRow.push('');
      });

      emptyRow.push(''); // date
      return emptyRow;
    }

    const height = operation.form.results.height?.value || operation.form.results.height;
    const realVolume = (operation.form.results.volume?.value || operation.form.results.volume);
    const volume = height === '0' && realVolume !== '0' ? `0 ↔ ${realVolume}` : (operation.form.results.volume?.value || operation.form.results.volume);

    const row = [
      operation.cellarName || operation.cellar,
      operation.tankName || operation.tankNumber,
      height,
      volume,
      operation.form.paConversionResults?.abvAt20c?.value || operation.form.results.volumeConvResult?.abvAt20c?.value || '',
      operation.form.paConversionResults?.pureAlcoholVolumeAt20c?.value || operation.form.results.volumeConvResult?.pureAlcoholVolumeAt20c?.value || '',
      maxVolume,
      ...extraOperationFields.map(field => {
        const storedField = operation.form.extraFields?.find((f) => f.name === field.name);
        return storedField?.value || '';
      }),
      new Date(operation.dateAndTime.seconds * 1000 + operation.dateAndTime.nanoseconds / 1000000)
        .toLocaleString('fr-FR', { hour12: false })
    ];

    return row;
  });

  const data = await Promise.all(dataPromises);

  // Add data to the worksheet
  XLSX.utils.sheet_add_json(ws, data, { skipHeader: true, origin: 'A2' });
};

export const generateTodayOperationsSheet = (ws2, todayOps, volumeUnit, t) => {
  const todayHeaders = [
    t('daily_inventory.headers.last_op_date'),
    'Produit',
    t('daily_inventory.headers.tankName'),
    t('daily_inventory.headers.finalHeight'),
    t('daily_inventory.headers.finalVolume', { unit: volumeUnit }),
    t('daily_inventory.headers.abv'),
    t('daily_inventory.headers.temperature'),
    t('daily_inventory.headers.abvAt20c'),
    '',
    t('daily_inventory.headers.volumeAt20C', { unit: volumeUnit }),
    t('daily_inventory.headers.pureAlcohol', { unit: volumeUnit }),
  ];

  XLSX.utils.sheet_add_aoa(ws2, [todayHeaders], { origin: 'A1' });

  const todayData = todayOps.map(op => [
    new Date(op.dateAndTime.seconds * 1000 + op.dateAndTime.nanoseconds / 1000000)
      .toLocaleDateString('fr-FR'),
    op.form.extraFields?.find(f => f.name === 'cuve-disponible')?.value || '',
    op.tankName || op.tankNumber,
    op.form.results.height?.value || op.form.results.height || '',
    op.form.results.volume?.value || op.form.results.volume || '',
    op.form.tavInputs.measuredTav?.value || '',
    op.form.tavInputs.tavTemperature?.value || '',
    op.form.paConversionResults?.abvAt20c?.value || op.form.results.volumeConvResult?.abvAt20c?.value || '',
    '',
    op.form.paConversionResults?.volumeAtTemp?.value || op.form.results.volumeConvResult?.volumeAtTemp?.value || '',
    op.form.paConversionResults?.pureAlcoholVolumeAt20c?.value || op.form.results.volumeConvResult?.pureAlcoholVolumeAt20c?.value || '',
  ]);

  XLSX.utils.sheet_add_json(ws2, todayData, { skipHeader: true, origin: 'A2' });

  ws2['!cols'] = [
    { wch: 30 },
    { wch: 20 },
    { wch: 20 },
    { wch: 20 },
    { wch: 20 },
    { wch: 20 },
    { wch: 20 },
    { wch: 20 },
    { wch: 10 },
    { wch: 20 },
    { wch: 20 },
  ];
};

export const generateCellarSummarySheet = (ws3, lastOps, volumeUnit, t) => {
  const summaryHeaders = [
    t('daily_inventory.headers.cellarName'),
    t('daily_inventory.headers.volumeAt20C', { unit: volumeUnit }),
    t('daily_inventory.headers.pureAlcohol', { unit: volumeUnit }),
  ];

  XLSX.utils.sheet_add_aoa(ws3, [summaryHeaders], { origin: 'A1' });

  const cellarSummaries = lastOps.reduce((acc, op) => {
    const cellarName = op.cellarName || op.cellar;
    if (!acc[cellarName]) {
      acc[cellarName] = { volumeAt20C: 0, pureAlcohol: 0 };
    }
    
    const volumeAt20C = parseFloat(op.form.paConversionResults?.volumeAtTemp?.value || op.form.results.volumeConvResult?.volumeAtTemp?.value || 0);
    const pureAlcohol = parseFloat(
      op.form.paConversionResults?.pureAlcoholVolumeAt20c?.value || 
      op.form.results.volumeConvResult?.pureAlcoholVolumeAt20c?.value || 
      0
    );

    acc[cellarName].volumeAt20C += volumeAt20C;
    acc[cellarName].pureAlcohol += pureAlcohol;
    
    return acc;
  }, {});

  const summaryData = Object.entries(cellarSummaries).map(([cellar, data]) => [
    cellar,
    roundNoFixed(data.volumeAt20C, 4),
    roundNoFixed(data.pureAlcohol, 4)
  ]);

  XLSX.utils.sheet_add_json(ws3, summaryData, { skipHeader: true, origin: 'A2' });

  ws3['!cols'] = [
    { wch: 30 },
    { wch: 20 },
    { wch: 20 },
  ];
}; 