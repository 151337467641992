import React from 'react';
import { useTranslation } from 'react-i18next';
import Done from '@mui/icons-material/Done';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { LoadingButton } from '@mui/lab';
import useGlobal from 'global-state/store';
import { useNavigate } from 'react-router-dom';
import { getAnalytics } from 'firebase/analytics';
import { useFirestore } from 'firebaseHooks/FirestoreContext';
import { useAuth } from 'firebaseHooks/AuthContext';
import saveOperationToFirestore from '../firestoreSaveOperation';
import EmptyContainerButton from '../EmptyContainerButton';

export default function OperationButtons(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const db = useFirestore();
  const { currentUser: user } = useAuth();
  const analytics = getAnalytics();
  const [globalState, globalActions] = useGlobal();

  const {
    certificatePath, tankNumber, cellar, tankName, cellarName, 
    certificateName, getForm, isLoading,
  } = props;

  const handleClickSave = () => {
    if (validate()) {
      // No need to await here the setting of doc especially because that make the feature work offline
      saveOperationToFirestore(
        db,
        globalState.clientPath,
        analytics,
        user,
        tankNumber,
        certificatePath,
        cellar,
        tankName,
        cellarName,
        certificateName,
        getForm(),
        {},
        globalState.volumeConversionMethod,
      );

      globalActions.setSnackbarMessage({
        message: globalState.networkState !== 'offline'
          ? t('operation.save_success_text')
          : t('operation.offline_save_warning'),
        severity: 'success'
      });
      navigate('/');

    }
  };

  function alertWithError(message) {
    globalActions.setSnackbarMessage({
      message,
      severity: 'warning'
    });
  }

  function validate() {
    if (getForm().results.volume.value <= 0 || getForm().results.volume.value === '-') {
      alertWithError(t('operation.total_volume_zero'));
      return false;
    }
    return true;
  }

  function validateNetwork() {
    if (globalState.networkState === 'offline') {
      alertWithError(t('forms.need_network'));
      return false;
    }
    return true;
  }

  const handleGoToVolumeConversion = () => {
    const hookUrl = new URL(window.location);
    const volumeDeeplink = new URL('https://alcoholometry.labox-apps.com');
    volumeDeeplink.searchParams.append('type', 'volumeConversion');
    volumeDeeplink.searchParams.append('hookName', 'DigiTank GC');
    volumeDeeplink.searchParams.append('hookUrl', hookUrl);
    volumeDeeplink.searchParams.append('volumeUnit', 'hL');
    volumeDeeplink.searchParams.append('volumeUnit', 'hL');

    if (validateNetwork()) {
      window.location.assign(volumeDeeplink.href);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        flexWrap: 'wrap',
        gap: 3,
        mt: 3,
      }}
    >
      <LoadingButton
        loading={isLoading}
        variant="contained"
        onClick={handleClickSave}
        endIcon={<Done />}
      >
        {t('operation.done')}
      </LoadingButton>

      <EmptyContainerButton
        certificatePath={certificatePath}
        tankNumber={tankNumber}
        cellar={cellar}
        getForm={getForm}
        tankName={tankName}
        cellarName={cellarName}
        certificateName={certificateName}
      />

      <Button
        variant="text"
        size="small"
        onClick={handleGoToVolumeConversion}
        endIcon={<ExitToAppIcon />}
      >
        {t('to_alcoholometry')}
      </Button>
    </Box>
  );
}
